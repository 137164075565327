<template>
	<div class="bg">
		<video controls ref="initAudio" style="position: absolute;left: -300px;bottom: -300px;opacity: 0;">
			<source :src="mp3" type='audio/mpeg'>
		</video>
		<div class="yyan" v-if="mp3!='https://www.dmclkj.com:9527/upload/20220820/tnp.mp3' && tzxz==false" :class="{ tzxz: tzxz }" @click="ztbf()">
			<el-tooltip class="item" effect="dark" content="暂停(再次)播放" placement="top-start">
				<img src="../assets/img/mp3.png"></img>
			</el-tooltip>
		</div>
		<div class="bg-left" v-if="leftW==250" :style="{width:`${leftW}PX`}">
			<div class="bg-left-bt">{{bt}}</div>
			<div class="ejmlss">
				<el-autocomplete
				  popper-class="my-autocomplete"
				  v-model="state"
				  :fetch-suggestions="querySearch"
				  placeholder="搜索"
				  @select="handleSelect"
				  size="mini"
				  class="sssrk">
				  <i
				    class="el-icon-search el-input__icon"
				    slot="suffix">
				  </i>
				  <template slot-scope="{ item }">
				    <div class="name">{{ item.name }}</div>
				  </template>
				</el-autocomplete>
			</div>
			<div class="bg-left-bt-fgx"></div>
			<div class="bg-left-bt-ml">
				<div class="class-menu">
					<div  :title="item.name" v-for="(item, index) in menuList" :key="item.id" ref='yj' class="yjss" style="height: 34PX;">
						<div class="class-one" @click="djyj(index)">
							<img src="../assets/img/xjt.png" class="class-one-icon">
							{{item.name}}
						</div>
						<div :title="item1.name" v-for="(item1, index1) in item.ckasstwo" class="class-tow" :class="{menuTowOn:activeTow==item1.id}" @click="ejOn(index,index1,item1.name,item1.id)">{{item1.name}}</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="leftW==250" class="bg-left-shrink" @click="leftW=0,shrink()" :style="{left:`${leftW}PX`}">&lt;</div>
		<div v-if="leftW!=250" class="bg-left-shrink" @click="leftW=250,open()" :style="{left:`${leftW}PX`}">&gt;</div>
		<div class="bg-right" :style="{width:`calc(100% - ${leftW}PX)`}">
			<div class="bg-right-nav">
				{{noeNamem}}&nbsp;&nbsp;&nbsp;&nbsp;&gt;&nbsp;&nbsp;&nbsp;&nbsp;{{twoamem}}&nbsp;&nbsp;&nbsp;&nbsp;&gt;&nbsp;&nbsp;&nbsp;&nbsp;{{threeNamem}}
				<el-switch
				  v-model="tzxz"
				  inactive-text="关闭语音"
				  active-color="#13ce66"
				  inactive-color="#DCDFE6"
				  @change='cfgbyy'
				  style="margin-left: 40PX;">
				</el-switch>
				<div class="xyjsyj">
					<div class="xyy" @click="syb(three-1)">上一步</div>
					<div class="xyy" @click="qh(three+1)">下一步</div>
				</div>
			</div>
			<div class="bg-right-content">
				<div v-if="content.content" class="bg-right-content-img" :style="scaleFun">
					<img :src="content.content.bgImage" class="menu-bg" @load='loadImg'>
					<div v-if="content1.content">
						<div v-for="(item, index) in content1.content.data" :key="item.id" style="z-index: 3;">
							<div @click="qh(three+1)" v-if="item.type=='box'" class="box" :style="{width: item.width +'PX',height: item.height +'PX',top: item.top +'PX',left: item.left +'PX','border-width':border+'PX'}"></div>
							<div v-if="item.type=='text'" class="text" :style="{width: item.width +'PX',height: item.height +'PX',top: item.top +'PX',left: item.left +'PX','font-size':font+'PX'}">{{item.content}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.ztzcbf{
	background: rgb(0,0,0,0.5);
	text-align: center;
	color: #FFFFFF;
	width: 100%;
	height: 100%;
	font-size: 12PX;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 2;
}
@keyframes fadenum{0%{ transform:rotate(0deg);}100%{transform:rotate(360deg);}}
@-moz-keyframes fadenum{0%{ transform:rotate(0deg);}100%{transform:rotate(360deg);}}
@-webkit-keyframes fadenum{0%{ transform:rotate(0deg);}100%{transform:rotate(360deg);}}
@-o-keyframes fadenum{0%{ transform:rotate(0deg);}100%{transform:rotate(360deg);}}
.yyan{
	position: fixed;
	right: 26PX;
	bottom: 35vh;
	width: 60PX;
	height: 60PX;
	background: #FFFFFF;
	z-index: 999;
	border-radius: 30PX;
	box-shadow: 0 1PX 10PX #e8e6e6;
	border: 2PX solid #d2d2d2;
	color: #FFFFFF;
	box-sizing: border-box;
	padding-top: 13PX;
	animation:fadenum 4s infinite linear;
	-moz-animation:fadenum 4s infinite linear;
	-webkit-animation:fadenum 4s infinite linear;
	-o-animation:fadenum 4s infinite linear;
	cursor: pointer;
	overflow: hidden;
}
.yyan:hover{
	background: #dff7f0;
}
.tzxz{
	animation-play-state:paused;
}
.yyan img{
	display: block;
	width: 30PX;
	margin: 0 auto;
	position: relative;
	z-index: 1;
}
.sssrk{
	width: 100%;
}
.ejmlss{
	width: 100%;
	box-sizing: border-box;
	padding-left: 20PX;
	padding-right: 20PX;
	margin-top: 15PX;
	margin-bottom: 5PX;
}
.yjss{
	overflow: hidden;
}
.xyy{
	height: 100%;
	width: 60PX;
	background: #33ba8d;
	margin-left: 20PX;
	color: #FFFFFF;
	font-size: 14PX;
	line-height: 28PX;
	text-align: center;
	float: left;
	cursor: pointer;
}
.xyy:hover{
	opacity: 0.7;
}
.xyjsyj{
	height: 28PX;
	position: absolute;
	right: 20PX;
	top: 6PX;
	overflow: hidden;
}
.text{
	overflow: hidden;
	background:none;  
	outline:none;  
	border:none;
	resize:none;
	line-height: 28PX;
	font-weight: bold;
	color: rgba(230, 44, 44, 1);
	background: #FFFFFF;
	position: absolute;
	z-index: 2;
	transform-origin: top left;
}
.text:focus{   
	border:none;
}
.box{
	box-sizing: border-box;
	border-style: solid;
	border-color: rgba(230, 44, 44, 1);
	border-style: solid;
	border-width: ;
	background: rgba(230, 44, 44, 0.01);
	position: absolute;
	z-index: 2;
	transform-origin: top left;
}
.menu-bg{
	display: block;
	width: 100%;
	z-index: -1;
	margin: 0 auto;
	image-rendering: -moz-crisp-edges; 
	image-rendering: -o-crisp-edges; 
	image-rendering: -webkit-optimize-contrast; 
	image-rendering: crisp-edges; 
	-ms-interpolation-mode: nearest-neighbor;
}
.bg-right-content-img{
	width: 1260PX;
	position: relative;
	transform-origin: top left;
}
.bg-right-content{
	width: 100%;
	height: calc(100% - 40PX);
	box-sizing: border-box;
	padding: 20PX;
	padding-top: 0;
	overflow: auto;
	position: relative;
}
.bg-right-nav{
	width: 100%;
	height: 40PX;
	font-size: 14PX;
	line-height: 40PX;
	box-sizing: border-box;
	padding-left: 20PX;
	color: #575d6c;
	position: relative;
}
.bg-left-shrink{
	position: absolute;
	width: 20PX;
	height: 50PX;
	background: #f2f6f7;
	top: 50%;
	margin-top: -25PX;
	box-sizing: border-box;
	border: 1PX solid rgba(219, 219, 219, 0.7);
	border-radius: 0PX 5PX 5PX 0PX;
	color: #3e464c;
	font-size: 18PX;
	text-align: center;
	line-height: 48PX;
	cursor: pointer;
	transition: 0.2s;
	z-index: 9;
}
.bg-left-shrink:hover{
	border: 1PX solid rgba(51, 168, 141, 0.3);
	color: #33ba8d;
}
.bg-right{
	height: 100%;
	float: left;
	background: #FFFFFF;
	transition: 0.2s;
}
.class-tow{
	width: 100%;
	font-size: 14PX;
	height: 34PX;
	line-height: 34PX;
	color: #3e464c;
	box-sizing: border-box;
	padding-left: 26PX;
	cursor: pointer;
	transition: 0.2s;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.class-tow:hover{
	color: #33ba8d;
}
.class-one-icon{
	position: absolute;
	display: block;
	height: 9PX;
	left: 0;
	top: 13PX;
	transition: 0.2s;
}
.menuIconOn{
	transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	-webkit-transform: rotate(90deg);
	-o-transform: rotate(90deg);
}
.class-one{
	width: 100%;
	font-size: 14PX;
	height: 34PX;
	line-height: 34PX;
	color: #3e464c;
	box-sizing: border-box;
	padding-left: 18PX;
	position: relative;
	cursor: pointer;
	transition: 0.2s;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.class-one:hover{
	color: #33ba8d;
}
.menuTowOn{
	color: #33ba8d;
}
.bg-left-bt-ml{
	width: 100%;
	box-sizing: border-box;
	padding-right: 20PX;
	padding-left: 20PX;
	height: calc(100% - 100PX);
	overflow-y: auto;
}
.bg-left-bt-fgx{
	width: calc(100% - 40PX);
	height: 1PX;
	margin: 0 auto;
	background: #dbdbdb;
	margin-top: 20PX;
	margin-bottom: 20PX;
}
.bg-left-bt{
	color: #3e464c;
	font-size: 18PX;
	line-height: 20PX;
	padding-left: 20PX;
	padding-right: 20PX;
	box-sizing: border-box;
	font-weight: 700;
}
.bg-left{
	height: 100%;
	background: #f2f6f7;
	float: left;
	box-sizing: border-box;
	padding-top: 20PX;
	padding-bottom: 20PX;
	float: left;
	position: relative;
}
.logo{
	display: block;
	position: absolute;
	height: 27PX;
	left: 20PX;
	top: 11PX;
}
.bg{
	width: 100vw;
	height: 100vh;
	position: relative;
	box-sizing: border-box;
	background: #ffffff;
	overflow: hidden;
	position: relative;
}
</style>

<script>
export default {
	name: 'train',
	data () {
		return {
			bt:'',
			noeNamem:'',
			twoamem:'',
			threeNamem:'',
			type:'',
			leftW:250,
			activeOne:1,
			activeOneIdx:0,
			activeTow:1,
			activeTowIdx:0,
			zoom:1,
			font:15,
			border:6,
			menuList:[],
			menuData:[],
			content:[],
			content1:[],
			two:0,
			three:0,
			restaurants: [],
			state: '',
			timeout:  null,
			mp3:'https://www.dmclkj.com:9527/upload/20220820/tnp.mp3',
			tzxz:false
		}
	},
	computed:{
		scaleFun () {
			let scale = this.zoom;
		    return `transform:scale(${scale},${scale})`
		},
		whlt (w,h,t,l) {
		    return `width:${w}px,height:${h}px,left:${l}px,top:${t}px`
		}
	},
	mounted () {
		let that=this;
		let pmW = document.documentElement.clientWidth;
		this.pmW = pmW;
		if(pmW>=1600 && pmW<=1920){
			this.zoom = 1.28;
		}else if(pmW>=1300 && pmW<=1599){
			this.zoom = 0.98;
		}else{
			this.zoom = 0.78;
		}
		that.type = that.$route.params.id;
		if(that.$route.params.id==1){
			that.bt = '试算附注';
		}else if(that.$route.params.id==2){
			that.bt ='函证中心';
		}else{
			that.bt ='电子底稿';
		}
		that.$http('/getclass?type='+that.type).then(function (res) {
			that.menuList = res.data.data;
			for (let i = 0; i < that.menuList.length; i++) {
				if(that.menuList[i].ckasstwo){
					let item = that.menuList[i].ckasstwo;
					for (let e = 0; e < item.length; e++) {
						if(item[e]){
							let item1 = item[e];
							item1.idx = e;
							item1.fjidx = i;
							that.restaurants.push(item1);
						}
					}
				}
				
			}
			if(res.data.data[0].id!=''){
				that.activeOne = res.data.data[0].id;
				that.noeNamem = res.data.data[0].name;
			}
			if(res.data.data[0].ckasstwo!=''){
				that.activeTow= res.data.data[0].ckasstwo[0].id;
				that.twoamem = res.data.data[0].ckasstwo[0].name;
				that.$http('/classthree?parent='+res.data.data[0].ckasstwo[0].id).then(function (e) {
					if(e.data.data!=''){
						that.content1=[];
						that.menuData = e.data.data;
						let sj = that.menuData[0];
						that.threeNamem = sj.name;
						sj.content = JSON.parse(sj.content);
						that.content = sj;
						that.$refs.yj[0].style.height = '';
					}
				}).catch(function (error) {
					
				});
			}
		}).catch(function (error) {
			
		});
	},
	methods: {
		cfgbyy(e){
			if(e==true){
				this.$refs.initAudio.load();
			}else{
				this.$refs.initAudio.play();
			}
		},
		ztbf(){
			if(this.$refs.initAudio.paused==true){
				this.$refs.initAudio.play();
			}else{
				this.$refs.initAudio.load();
			}
		},
		querySearch(queryString, cb) {
			var restaurants = this.restaurants;
			var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
			cb(results);
		},
		createFilter(queryString) {
			return (restaurant) => {
				return (restaurant.name.toLowerCase().indexOf(queryString.toLowerCase()) >= 0);
			};
		},
		handleSelect(item) {
			let that=this;
			if(that.activeTow!=item.id){
				that.$refs.yj[item.fjidx].style.height = '';
				that.activeOne = that.menuList[item.fjidx].id;
				this.activeOneIdx=item.fjidx;
				that.noeNamem = that.menuList[item.fjidx].name;
				that.activeTow= item.id;
				that.twoamem = item.name;
				that.activeTowIdx=item.idx;
				that.$http('/classthree?parent='+item.id).then(function (e) {
					if(e.data.data!=''){
						that.content1=[];
						that.menuData = e.data.data;
						let sj = that.menuData[0];
						that.threeNamem = sj.name;
						sj.content = JSON.parse(sj.content);
						that.content = sj;
						if(sj.bgmp3){
							that.mp3 = sj.bgmp3;
							that.$refs.initAudio.src = sj.bgmp3;
							if(that.tzxz==false){
								this.$refs.initAudio.play();
							}
						}else{
							that.mp3 = 'https://www.dmclkj.com:9527/upload/20220820/tnp.mp3';
							that.$refs.initAudio.src = that.mp3;
							this.$refs.initAudio.pause();
						}
					}
				}).catch(function (error) {
					
				});
			}
		},
		loadImg(){
			this.content1 = this.content;
		},
		hqfid(a){
			let that=this;
			for (let i = 0; i < that.menuList.length; i++) {
				let item = that.menuList[i].ckasstwo;
				for (let e = 0; e < item.length; e++) {
					let item1 = item[e].id;
					if(item1==a){
						return i;
					}
				}
			}
			
		},
		djyj(b){
			let that=this;
			if(this.$refs.yj[b].style.height==''){
				this.$refs.yj[b].style.height = '34PX';
			}else{
				this.$refs.yj[b].style.height = '';
			}
			this.activeOneIdx=b;
		},
		shrink(){
			if(this.pmW>=1600 && this.pmW<=1920){
				this.zoom = 1.46;
			}else if(this.pmW>=1300 && this.pmW<=1599){
				this.zoom = 1.16;
			}else{
				this.zoom = 0.96;
			}
		},
		open(){
			if(this.pmW>=1600 && this.pmW<=1920){
				this.zoom = 1.28;
			}else if(this.pmW>=1300 && this.pmW<=1599){
				this.zoom = 0.98;
			}else{
				this.zoom = 0.78;
			}
		},
		syb(a){
			let that=this;
			if(a>=0){
				that.content1=[];
				that.three = that.three-1;
				let sj = that.menuData[that.three];
				that.threeNamem = sj.name;
				if(!sj.content.bgImage){
					sj.content = JSON.parse(sj.content);
				}
				that.content = sj;
				if(sj.bgmp3){
					that.mp3 = sj.bgmp3;
					that.$refs.initAudio.src = sj.bgmp3;
					if(that.tzxz==false){
						this.$refs.initAudio.play();
					}
				}else{
					that.mp3 = 'https://www.dmclkj.com:9527/upload/20220820/tnp.mp3';
					that.$refs.initAudio.src = that.mp3;
					this.$refs.initAudio.pause();
				}
			}else{
				let yjidx = that.hqfid(that.activeTow);
				if((that.activeTowIdx-1)>=0){
					that.$http('/classthree?parent='+that.menuList[yjidx].ckasstwo[that.activeTowIdx-1].id).then(function (e) {
						if(e.data.data!=''){
							that.content1=[];
							that.activeTow=that.menuList[yjidx].ckasstwo[that.activeTowIdx-1].id;
							that.twoamem=that.menuList[yjidx].ckasstwo[that.activeTowIdx-1].name;
							that.activeTowIdx=that.activeTowIdx-1;
							that.menuData = e.data.data;
							let sj = that.menuData[that.menuData.length-1];
							that.threeNamem = sj.name;
							sj.content = JSON.parse(sj.content);
							that.content = sj;
							that.three=that.menuData.length-1;
							if(sj.bgmp3){
								that.mp3 = sj.bgmp3;
								that.$refs.initAudio.src = sj.bgmp3;
								if(that.tzxz==false){
									this.$refs.initAudio.play();
								}
							}else{
								that.mp3 = 'https://www.dmclkj.com:9527/upload/20220820/tnp.mp3';
								that.$refs.initAudio.src = that.mp3;
								this.$refs.initAudio.pause();
							}
						}
					}).catch(function (error) {
						
					});
				}else{
					if((yjidx-1)>=0){
						let ejcd = that.menuList[yjidx-1].ckasstwo.length-1;
						that.$http('/classthree?parent='+that.menuList[yjidx-1].ckasstwo[ejcd].id).then(function (e) {
							if(e.data.data!=''){
								that.content1=[];
								that.activeTow=that.menuList[yjidx-1].ckasstwo[ejcd].id;
								that.noeNamem=that.menuList[yjidx-1].name;
								that.twoamem=that.menuList[yjidx-1].ckasstwo[ejcd].name;
								that.$refs.yj[yjidx-1].style.height = '';
								that.activeTowIdx=ejcd;
								that.menuData = e.data.data;
								let sj = that.menuData[that.menuData.length-1];
								that.threeNamem = sj.name;
								sj.content = JSON.parse(sj.content);
								that.content = sj;
								that.three=that.menuData.length-1;
								if(sj.bgmp3){
									that.mp3 = sj.bgmp3;
									that.$refs.initAudio.src = sj.bgmp3;
									if(that.tzxz==false){
										this.$refs.initAudio.play();
									}
								}else{
									that.mp3 = 'https://www.dmclkj.com:9527/upload/20220820/tnp.mp3';
									that.$refs.initAudio.src = that.mp3;
									this.$refs.initAudio.pause();
								}
							}
						}).catch(function (error) {
							
						});
					}else{
						that.$alert('已经是第一页', '提示', {
							confirmButtonText: '确定',
							type: 'warning',
						    callback: action => {
									  
							}
						});
					}
				}
			}
		},
		qh(a){
			let that=this;
			if(a<that.menuData.length){
				that.content1=[];
				that.three = that.three+1;
				let sj = that.menuData[that.three];
				that.threeNamem = sj.name;
				if(!sj.content.bgImage){
					sj.content = JSON.parse(sj.content);
				}
				that.content = sj;
				if(sj.bgmp3){
					that.mp3 = sj.bgmp3;
					that.$refs.initAudio.src = sj.bgmp3;
					if(that.tzxz==false){
						this.$refs.initAudio.play();
					}
				}else{
					that.mp3 = 'https://www.dmclkj.com:9527/upload/20220820/tnp.mp3';
					that.$refs.initAudio.src = that.mp3;
					this.$refs.initAudio.pause();
				}
			}else{
				let yjidx = that.hqfid(that.activeTow);
				if((that.activeTowIdx+1)<that.menuList[yjidx].ckasstwo.length){
					that.$http('/classthree?parent='+that.menuList[yjidx].ckasstwo[that.activeTowIdx+1].id).then(function (e) {
						if(e.data.data!=''){
							that.content1=[];
							that.activeTow=that.menuList[yjidx].ckasstwo[that.activeTowIdx+1].id;
							that.twoamem=that.menuList[yjidx].ckasstwo[that.activeTowIdx+1].name;
							that.activeTowIdx=that.activeTowIdx+1;
							that.menuData = e.data.data;
							let sj = that.menuData[0];
							that.threeNamem = sj.name;
							sj.content = JSON.parse(sj.content);
							that.content = sj;
							that.three=0;
							if(sj.bgmp3){
								that.mp3 = sj.bgmp3;
								that.$refs.initAudio.src = sj.bgmp3;
								if(that.tzxz==false){
									this.$refs.initAudio.play();
								}
							}else{
								that.mp3 = 'https://www.dmclkj.com:9527/upload/20220820/tnp.mp3';
								that.$refs.initAudio.src = that.mp3;
								this.$refs.initAudio.pause();
							}
						}
					}).catch(function (error) {
						
					});
				}else{
					if((yjidx+1)<that.menuList.length){
						that.$http('/classthree?parent='+that.menuList[yjidx+1].ckasstwo[0].id).then(function (e) {
							if(e.data.data!=''){
								that.content1=[];
								that.activeTow=that.menuList[yjidx+1].ckasstwo[0].id;
								that.noeNamem=that.menuList[yjidx+1].name;
								that.twoamem=that.menuList[yjidx+1].ckasstwo[0].name;
								that.$refs.yj[yjidx+1].style.height = '';
								that.activeTowIdx=0;
								that.menuData = e.data.data;
								let sj = that.menuData[0];
								that.threeNamem = sj.name;
								sj.content = JSON.parse(sj.content);
								that.content = sj;
								that.three=0;
								if(sj.bgmp3){
									that.mp3 = sj.bgmp3;
									that.$refs.initAudio.src = sj.bgmp3;
									if(that.tzxz==false){
										this.$refs.initAudio.play();
									}
								}else{
									that.mp3 = 'https://www.dmclkj.com:9527/upload/20220820/tnp.mp3';
									that.$refs.initAudio.src = that.mp3;
									this.$refs.initAudio.pause();
								}
							}
						}).catch(function (error) {
							
						});
					}else{
						that.$alert('已经是最后一页', '提示', {
							confirmButtonText: '确定',
							type: 'warning',
						    callback: action => {
									  
							}
						});
					}
				}
			}
		},
		ejOn(idx,idx1,name,id){
			let that=this;
			if(that.activeTow!=id){
				that.activeTow=id;
				that.activeTowIdx=idx1,
				that.twoamem = name;
				that.$http('/classthree?parent='+id).then(function (e) {
					if(e.data.data!=''){
						that.content1=[];
						that.noeNamem=that.menuList[idx].name;
						that.activeTowIdx = idx1;
						that.twoamem = name;
						that.menuData = e.data.data;
						let sj = that.menuData[0]
						that.threeNamem = sj.name;
						sj.content = JSON.parse(sj.content);
						that.content = sj;
						that.three=0;
						if(sj.bgmp3){
							that.mp3 = sj.bgmp3;
							that.$refs.initAudio.src = sj.bgmp3;
							if(that.tzxz==false){
								this.$refs.initAudio.play();
							}
						}else{
							that.mp3 = 'https://www.dmclkj.com:9527/upload/20220820/tnp.mp3';
							that.$refs.initAudio.src = that.mp3;
							this.$refs.initAudio.pause();
						}
					}
				}).catch(function (error) {
					
				});
			}
		}
	}
};
</script>
